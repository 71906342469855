<template>
  <div class="mt-5">
    <product-detail v-if="product" :product="product" :loading="loading" @refresh="loadProduct"/>
    <div v-else class="row" style="min-height: 100vh;">
      <div class="col-md-12">
        <skeleton :width="300" :height="30" />
      </div>
      <div class="col-xl-5 col-lg-6 col-md-12 product-img">
        <skeleton :width="500" :height="500" class="mt-3"/>
        <div class="row swiper-product-list">
          <skeleton :width="100" :height="100" :percentage="true" class="col-3 col-md-3 mt-3"/>
          <skeleton :width="100" :height="100" :percentage="true" class="col-3 col-md-3 mt-3"/>
          <skeleton :width="100" :height="100" :percentage="true" class="col-3 col-md-3 mt-3"/>
          <skeleton :width="100" :height="100" :percentage="true" class="col-3 col-md-3 mt-3"/>
        </div>
      </div>
      <div class="col-xl-7 col-lg-6 col-md-12 mt-4 mt-md-4 mt-lg-0">
        <skeleton :width="100" :height="30" :percentage="true"/>
        <skeleton :width="100" :height="100" :percentage="true" class="mt-2" />
        <skeleton :width="100" :height="110" :percentage="true" class="mt-2" />
        <skeleton :width="100" :height="150" :percentage="true" class="mt-2" />
        <div class="d-flex mt-4">
          <skeleton :width="200" :height="60" class="me-4" :borderRadius="50"/>
          <skeleton :width="200" :height="60" :borderRadius="50"/>
        </div>
        <skeleton :width="100" :height="150" :percentage="true" class="mt-4" />
        <skeleton :width="100" :height="400" :percentage="true" class="mt-4" />
      </div>
    </div>
    <section class="mt-0 bg-white position-relative" style="padding-top: 4.2rem; z-index: 7;">
      <div class="d-flex justify-content-between align-items-center">
        <main-nav-tab :options="optionsTab" :selectedOption="selectedOption" @select="(data) => {changeOption(data)}" class="products-navigation" style="max-width: 860px;"/>
          <div>
            <div class="similar-swiper-button-prev d-inline" style="margin-right: 15px;" slot="button-prev">
                <icon-arrow-circle class="fill-gray-50" :size="35"/>
            </div>
            <div class="similar-swiper-button-next d-inline" slot="button-next">
                <icon-arrow-circle class="fill-gray-50" style="transform: rotate(180deg)" :size="35"/>
            </div>
          </div>
        </div>
        <swiper :options="swiperOption" class="p-3 mt-3 mt-md-4">
          <swiper-slide :key="product.codarticulo" v-for="product in similarProducts">
            <card-product :product="product" :skeleton="false" @showQuickView="showQuickView" @showShare="showShare" class="m-auto"/>
          </swiper-slide>
          <div class="swiper-pagination position-relative" slot="pagination"></div> 
        </swiper>  
    </section>
    <scroll-up-button />
    <quick-view :product="quickView.product" v-if="quickView.value" @closeModal="quickView.value = false"/>
    <share v-if="share" @closeModal="share = false" :product="shareProduct"/>
  </div>
</template>
<script>
import  { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js"
import { mapGetters } from "vuex";
import { ProductService } from '@/modules/products/services/product-service.js'
import MainNavTab from '../../../common/components/tabs/MainNavTab.vue';
import CardProduct from '../components/CardProduct.vue';
import IconArrowCircle from '../../../common/svg/iconArrowCircle.vue';
import QuickView from '../components/quick-view/QuickView.vue';
import Share from '../components/modals/Share.vue';

import ScrollUpButton from '../../../common/components/buttons/ScrollUpButton.vue';
import Skeleton from '../../../common/components/skeleton/Skeleton.vue';
const service = new ProductService()

export default {
  name: 'Products',
  components: {
    ProductDetail: () => import('@/modules/products/components/ProductDetail.vue'),
    MainNavTab,
    CardProduct,
    IconArrowCircle,
    QuickView,
    Share,
    ScrollUpButton,
    Skeleton,
  },
  data(){
    return{
      product: null,
      optionsTab: [
          {value: 'similar', title: 'product.similar_products'},
      ],
      selectedOption: {value: 'similar', title: 'product.similar_products'},
      similarProducts: [],
      swiperOption: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        navigation: {
          nextEl: '.similar-swiper-button-next',
          prevEl: '.similar-swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true
        },
        breakpoints: {
        0: {
            slidesPerView: 1.3,
            spaceBetween: 0,
            slidesPerColumn: 1,
            speed: 300
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 10,
            slidesPerColumn: 1,
            speed: 800,
        },
        // when window width is >= 768px
        768: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
            centeredSlides: false,
        },
        // when window width is >= 992px
        992: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 0,
        },
        // when window width is >= 1200px
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 0
        },
        1400: {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 0
        },
      },
      },
      shareProduct: {},
      quickView: { product: null, value: false},
      share: false,
      loading: false

    }
  },
  computed:{
      ...mapGetters({
            isLogged:"auth/isLogged",
            notify: "common/notify",
            branchSelected: "branches/branchSelected",
            user:"auth/userData",
        }),
  },
    watch: {
      //In case we are already on this page we will load the product on the url again
        '$route': function(){
            this.loadProduct();
        },
        branchSelected(){
          this.loadProduct();
        },
        user:{
            deep: true,
            async handler(){
              this.loadProduct();                
            }
        }
    },
  mixins: [productMixin],
  methods: {
    async loadProduct(){
      try {
        // this.$store.commit("common/SET_STATE_LOADING", true)  
        this.product = await this.$store.dispatch('products/getProductByURL', { product_id: this.$route.params.id });   
        // console.log(this.product == null, 'llegaron parametros')
        if(!this.product) {
          this.$router.replace({path: `/shop`}).catch(() => {})
          this.$store.commit('common/SHOW_MODAL_ERROR', {
            show: true, 
            title: `Ocurrio un error`, 
            description: 'el codigo del producto que intenta introducir es incorrecto'
          })
          return;
        }
      } catch (error) {
        console.log(error)
      }finally {
        // this.$store.commit("common/SET_STATE_LOADING", false)
      }
    },
    async getSimilarProducts(){
      let branch = this.branchSelected.codplace || 1;
      let filterParams = {
          q: '', category: this.product.subcategory.sub_category_id, brands_selected:  '', limit: 10, prices: [0, 100000], subCategoriesSelected: null, 
          models_selected: '', gbSelected: [], place: branch, rated: null, page: 1, order_by: 'newest'
      }
      try {
        const response = await service.getProducts(filterParams)
        this.similarProducts = response.docs;
      } catch (error) {
        console.log(error);
      }
    },
    showQuickView({product, value}){
      this.quickView.product = product;
      this.quickView.value = value;
    },
    showShare(product){
      this.share = true;
      this.shareProduct = product
    }
  },
  async mounted(){
    try {
      this.loading = true;
      await this.loadProduct();
    } catch (error) {
      console.log(error);
    }finally {
      // this.$store.commit("common/SET_STATE_LOADING", false)
      this.loading = false;
    }
    await this.getSimilarProducts()
  },
  beforeCreate(){
    window.scrollTo({top: 0, behavior: 'instant'});
  },
}
</script>
<style scoped>
    .nav-tabs::v-deep > li > a{
        font-size: 24px;
    }
    .card-product::v-deep {
      max-width: 240px;
    }
    .card-product::v-deep:hover {
        transform: scale(1.05);
    }
    .swiper-pagination::v-deep .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
    }
    .swiper-container::v-deep .swiper-pagination {
        bottom: -50px;
    }
    .swiper-container::v-deep {
        padding-bottom: 73px !important;
    }
    .similar-swiper-button-prev.swiper-button-disabled, .similar-swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: no-drop !important;
          pointer-events: all !important;
      }
    .similar-swiper-button-prev.swiper-button-disabled:hover svg, .similar-swiper-button-next.swiper-button-disabled:hover svg {
        fill: var(--gray-100) !important;
    }
    .similar-swiper-button-prev:hover svg, .similar-swiper-button-next:hover svg {
        fill: var(--main-red) !important;
    } 
    .swiper-pagination::v-deep > .swiper-pagination-bullet{
        background-color: var(--gray-50);
    }
    .swiper-pagination::v-deep > .swiper-pagination-bullet:hover{
        transform: scale(0.8);
        background-color: var(--light-gray-501);
    }
    .swiper-product-list{
      width: 500px;
      margin-left: 2px;
    }
    @media (max-width: 1400px) {
      .product-img > .skeleton::v-deep > .square{
        width: auto !important;
      }
      .swiper-product-list{
        width: 100%;
        margin-left: 0;
      }
    }
</style>
